<template>
<div>
  <b-container class="my-4">
    <b-row class="mb-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Recent regulatory news</h1>
        <p>Explore recent news related to controls.
        </p>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loadingNews">
      <b-col>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <publication
            :propItem="item"
            :key="item.id"
            :tagsProp=tags
            :username="user.username"
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionArticlesWip="permissionArticlesWip"
            :permissionDeletePublication="permissionDeletePublication"
            :permissionInsights="permissionInsights"
            :permissionReadTagsPanel="permissionReadTagsPanel"
             />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="loadingNews" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'
import Publication from '@/components/PublicationNew'

export default {
  components: {
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "KPMG Controls News Feed"
    this.$store.commit('setBrand', 'Controls')
    this.$stat.log({ page: 'tax dashboard news', action: 'open tax dashboard news' })
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = true
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionDeletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permissionInsights = true
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
    }
    try {
      let apiName = 'cosmos'
      let path = `/tags`
      this.tags = await this.$Amplify.API.get(apiName, path)
    } catch (e) {
      this.$logger.warn('error getting events for a day', e)
    }
    this.load()
  },
  data () {
    return {
      delay: 60 * 1000,
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      item: {},
      limit: 20,
      loadingNews: true,
      news: [],
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      tags: []
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('load started')
      this.offset = this.page * this.limit
      try {
        this.news = await this.$Amplify.API.post('cosmos', '/news/topics/limit/20/offset/0', { body: { topics: [36, 115] } })
        this.loadingNews = false
        this.$store.commit('setWindowBottom', false)
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    }
  }
}
</script>

<style>
</style>
